@font-face {
font-family: '__LocalFonts_cb823b';
src: url(/_next/static/media/047df593d6d9da60-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__LocalFonts_cb823b';
src: url(/_next/static/media/27c49af78f3fab3b-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__LocalFonts_cb823b';
src: url(/_next/static/media/aad0c6fbcd08f50d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__LocalFonts_cb823b';
src: url(/_next/static/media/2aba17f0557acdaa-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}@font-face {font-family: '__LocalFonts_Fallback_cb823b';src: local("Arial");ascent-override: 90.73%;descent-override: 18.92%;line-gap-override: 2.72%;size-adjust: 103.05%
}.__className_cb823b {font-family: '__LocalFonts_cb823b', '__LocalFonts_Fallback_cb823b'
}

